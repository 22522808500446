import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const getMessageText = input => {
  if (typeof input === 'string') {
    return input
  } else if (typeof input === 'object' && input !== null) {
    if (Array.isArray(input) && input.length > 0) {
      return input[0]?.messages?.[0]?.message
    } else {
      return JSON.stringify(input)
    }
  } else {
    return input
  }
}

export default function MessageBox(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      className="modal-dialog-centered"
      modalClassName={'modal-warning'}
    >
      <ModalHeader toggle={() => props.setOpen(false)}>
        {getMessageText(props.head)}
      </ModalHeader>
      <ModalBody>{getMessageText(props.body)}</ModalBody>
      <ModalFooter>
        <Button
          color={'warning'}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
}
