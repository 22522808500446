import { useState } from "react";

export const useLoading = () => {
  const [isLoading, setLoading] = useState(false);

  const setValue = (value) => {
    setLoading(value);
  };

  return [isLoading, setValue];
};
