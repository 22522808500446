// ** React Imports
import { Suspense, lazy, useState } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";
import LoadingSpinner from "./@core/components/loading-spinner/Spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "./style.css";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { useLoading } from "./utility/hooks/useLoading";
import MessageBox from "./my_components/message_box";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const MyLoader = () => {
  const [isLoading, setLoading] = useLoading();
  global.setLoading = setLoading;
  return isLoading ? (
    <div
      style={{ position: "fixed", top: "300px", left: "40%", color: "black" }}
    >
      <LoadingSpinner />
    </div>
  ) : (
    <div />
  );
};

const MyMessageBox = () => {
  const [isOpen, setOpen] = useState(false);
  const [head, setHead] = useState("");
  const [body, setBody] = useState("");
  const showAlert = (head, body) => {
    setOpen(true);
    setHead(head);
    setBody(body);
  };
  global.showAlert = showAlert;
  return (
    <MessageBox isOpen={isOpen} setOpen={setOpen} head={head} body={body} />
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
        <MyLoader />
        <MyMessageBox />
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
