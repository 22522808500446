import "./spinner.css";

import { Backdrop, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Spinner = () => {
  // return (
  //   <div style={{height:'2px', width:'2px'}} className="drawing" id="loading">
  //     <div className="loading-dot"></div>
  //   </div>
  // );
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      open={true}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div
        style={{ height: "2px", width: "2px" }}
        className="drawing"
        id="loading"
      >
        <div className="loading-dot"></div>
      </div>
    </Backdrop>
  );
};

export default Spinner;
